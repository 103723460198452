<template>
  <component :is="layout"  />
</template>
<script>
import EmptyLayout from '@/Layouts/EmptyLayout.vue';
import WebLayout from '@/Layouts/WebLayout.vue';
import RegLayout from '@/Layouts/RegLayout.vue';
import UserLayout from '@/Layouts/UserLayout.vue';

export default {
  name: "App",
  data() {
    return {
      layout: null,
    };
  },
  components: {
    EmptyLayout,WebLayout,
    RegLayout,UserLayout
  },
  watch: {
    $route(to) {
      if (to.meta.layout !== undefined) {
        this.layout = to.meta.layout
      } else {
        this.layout = "EmptyLayout"
      }
    },
  },
};
</script>
