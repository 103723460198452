
<template class="">
    <nav class="pf ov ba bgOrange z1000 pv10" style="border-bottom-left-radius: 3rem; border-bottom-right-radius: 3rem;">
      <div class="flexi m_ph15">
        <tbl_nm>
          <cl class="vm l" style="width:125px">
            <router-link :to="{ name: 'Home' }"><img src="@/assets/imgs/logoV2.png" alt="logo" title="trendshift" class="w100"></router-link>
          </cl>
          <cl class="colSep50 m_h"></cl>
          <cl class="colSep pv20 h m_stc"></cl>

          <cl class="vm fs0  m_h l">
            <div class="g_nm ph10"><div class="navItem fs17" :class="{'active' : $route.name == 'Home' }"><router-link :to="{ name: 'Home' }">{{ $t('Home') }}</router-link></div></div>
            <div class="g_nm ph10"><div class="navItem fs17" :class="{'active' : $route.name == 'contact' }"><router-link :to="{ name: 'contact' }">{{ $t('Contact us') }}</router-link></div></div>
            <div class="g_nm ph10"><div class="navItem fs17" :class="{'active' : $route.name == 'terms' }"><router-link :to="{ name: 'terms' }">{{ $t('Terms of Use') }}</router-link></div></div>
          </cl>
          <cl class="colSep50 m_h"></cl>
          <cl class="vm r">
            <router-link :to="{ name: 'SignIn' }" class="m_h"><button class="navBtn"><i class="fa-solid fa-right-to-bracket"></i> {{ $t('Sign in Page') }}</button></router-link>
            <i class="fs25 vm fas fa-globe ml8 mr8 pointer cw" @click="changeLang()"></i> 
            <i class="fs25 vm fas fa-bars pointer cw h m_sib" @click="openSideBar()"></i> 
          </cl>
        </tbl_nm>
      </div>
    </nav>
    <div class=""><router-view></router-view></div>
    <div class="pf ov blackSideBar"></div>
    <div class="pf ov whiteSideBar"></div>
    <div class="pf ov orangeSideBar l">
      <div class="pv30 ph10 oya" style="max-height: 80vh;">
        <div class="ov20 la"><i class="fas fa-xmark pointer cw fs40" @click="closeSideBar()"></i></div>
        <img src="@/assets/imgs/logoV2.png" alt="logo" class="w90 g_nm" style="max-width: 140px">
        <div class="mt30 l">
          <div class="pv10 mt10"><div class="navItem fs20" :class="{'active' : $route.name == 'Home' }"><router-link :to="{ name: 'Home' }">{{ $t('Home') }}</router-link></div></div>
          <div class="pv10 mt10"><div class="navItem fs20" :class="{'active' : $route.name == 'contact' }"><router-link :to="{ name: 'contact' }">{{ $t('Contact us') }}</router-link></div></div>
          <div class="pv10 mt10"><div class="navItem fs20" :class="{'active' : $route.name == 'terms' }"><router-link :to="{ name: 'terms' }">{{ $t('Terms of Use') }}</router-link></div></div>
          <div class="mt20">
            <router-link :to="{ name: 'SignIn' }"><button class="navBtn"><i class="fa-solid fa-right-to-bracket"></i> {{ $t('Sign in Page') }}</button></router-link>
          </div>
        </div>
      </div>
    </div>
  <div v-if="$store.state.loader==1 || $store.state.pageLoader==1" class="pf ov bgw" style="z-index: 9999999;">
    <div class="cc">
      <div class="loader">$</div>
    </div>
  </div>
</template>
<script>
  export default {
    name: "WebLayout",
    created(){
      if(this.$cookies.isKey("_TrendLang")){
      if(this.$cookies.get("_TrendLang")=='en'){
        this.$i18n.locale='ar';
      }
      else{
        this.$i18n.locale='en';
      }
      this.changeLang()
    }
    },
    methods:{
      changeLang(){
        if(this.$i18n.locale=="en"){
          $('html').attr('lang', 'ar');  
          this.$i18n.locale='ar';
          $('body').addClass('rtl');
          const expirationTime =  10000 * 60 * 60;
          this.$cookies.set("_TrendLang", 'ar', expirationTime);
        }
        else{
          $('html').attr('lang', 'en');  
          this.$i18n.locale='en';
          $('body').removeClass('rtl');
          const expirationTime =  10000 * 60 * 60;
          this.$cookies.set("_TrendLang", 'en', expirationTime);
        }
      },
      openSideBar(){
        $('.blackSideBar').addClass('active')
        $('.orangeSideBar').addClass('active')
        $('.whiteSideBar').addClass('active')
      },
      closeSideBar(){
        $('.blackSideBar').removeClass('active')
        $('.orangeSideBar').removeClass('active')
        $('.whiteSideBar').removeClass('active')
      }
    },
    
  }
  
</script>
