import { createStore } from 'vuex'

export default createStore({
  state: {
    loader:0,
    pageLoader:0,
    formLoader:0,
    BaseApiURL: "https://trendsshift.com/api/",
    BaseImageURL: "https://trendsshift.com/storage/",
    emailRegex: /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/,
    checkNoSpical:/^[a-zA-Z0-9 ]*$/,
    checkNoSpicalNoSpace:/^[a-zA-Z0-9]*$/,
    checkNumber:/^[0-9]/,
    checkSpicalNoSpace:/^[a-zA-Z0-9!@#$%^&*_-]*$/,
    allowedImages:['jpg', 'jpeg', 'png'],
    

  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})
