
<template class="">
    <nav class="pf ov ba z2000 pv10">
      <div class="ph30 m_ph20 r">
        <div class="g_nm p15 round smBtn"><i class="fas fa-globe fs20 cc pointer " @click="changeLang()"></i></div>
        <div class="g_nm p15 round smBtn ml10"><i class="fas fa-bars pointer fs20 cc" @click="toggleSidebar()"></i> </div>
      </div>
    </nav>
    <div class="dashboardBg  mainDiv sideBarActive" :style="{ backgroundImage: 'url(' + require('@/assets/imgs/pattern.svg') + ')' }"><router-view></router-view></div>
    <nav class="sidebar"  :class="!isMobile ? 'active' : ''" id="sidebar">
        <div class="ov15 la bbs">
          <i class="fas fa-xmark pointer cw fs30 z1" @click="closeSideBar()"></i>
        </div>
        <header>
          <div class="image-text"><span class="image"><img src="@/assets/imgs/logoV1.png" alt="Logo" class="w100" style="max-width: 140px;"></span></div>
        </header>
        <div class="menu-bar">
            <div class="menu">
                <ul class="menu-links">
                    <li class="nav-link">
                        <router-link :class="this.$route.name == 'Dashboard' ? 'active' : ''" :to="{ name: 'Dashboard' }">
                            <i class='fas fa-home icon'></i>
                            <span class="text nav-text">{{ $t('Dashboard') }}</span>
                        </router-link>
                    </li>
                    <li class="nav-link op40" style="cursor: not-allowed;"> 
                        <i class='fas fa-money-bill icon'></i>
                        <span class="text nav-text pr25">{{ $t('Funds') }}</span>
                        <i class='fas fa-lock fs20 cw  ov mt15 mr5  la' v-if="user.subscription?.status !='subscribed'"></i>
                    </li>
                    <li class="nav-link op40" style="cursor: not-allowed;"> 
                        <i class='fas fa-handshake icon'></i>
                        <span class="text nav-text pr25">{{ $t('TrendsShif packge') }}</span>
                        <i class='fas fa-lock fs20 cw  ov mt15 mr5  la' v-if="user.subscription?.status !='subscribed'"></i>
                    </li>
                    <li class="nav-link op40" style="cursor: not-allowed;"> 
                        <i class='fas fa-users icon'></i>
                        <span class="text nav-text pr25">{{ $t('Team') }}</span>
                        <i class='fas fa-lock fs20 cw  ov mt15 mr5  la' v-if="user.subscription?.status !='subscribed'"></i>
                    </li>
                    <li class="nav-link op40" style="cursor: not-allowed;"> 
                      <!-- <router-link :class="this.$route.name == 'Ranks' ? 'active' : ''" :to="{ name: 'Ranks' }"> -->
                        <i class='fas fa-ranking-star icon'></i>
                        <span class="text nav-text pr25">{{ $t('Ranks') }}</span>
                        <i class='fas fa-lock fs20 cw  ov mt15 mr5  la' v-if="user.subscription?.status !='subscribed'"></i>
                      <!-- </router-link> -->
                    </li>
                    <li class="nav-link op40" style="cursor: not-allowed;"> 
                        <i class='fas fa-money-bill-transfer icon'></i>
                        <span class="text nav-text pr25">{{ $t('Commession/Profit') }}</span>
                        <i class='fas fa-lock fs20 cw  ov mt15 mr5  la' v-if="user.subscription?.status !='subscribed'"></i>
                    </li>
                    <li class="nav-link op40" style="cursor: not-allowed;"> 
                        <i class='fas fa-graduation-cap icon'></i>
                        <span class="text nav-text pr25">{{ $t('TrendsShift Academy') }}</span>
                        <i class='fas fa-lock fs20 cw  ov mt15 mr5  la' v-if="user.subscription?.status !='subscribed'"></i>
                    </li>
                    <li class="nav-link op40" style="cursor: not-allowed;"> 
                        <i class='fas fa-newspaper icon'></i>
                        <span class="text nav-text pr25">{{ $t('News & Tools') }}</span>
                        <i class='fas fa-lock fs20 cw  ov mt15 mr5  la' v-if="user.subscription?.status !='subscribed'"></i>
                    </li>
                    <!-- <li class="nav-link">
                        <router-link :class="this.$route.name == 'Transactions' ? 'active' : ''" to="/transactions">
                            <i class="fa-solid fa-money-bill-transfer icon"></i>
                            <span class="text nav-text">Transactions</span>
                        </router-link>
                    </li>
                    <li class="nav-link">
                        <router-link :class="this.$route.name == 'Community' ? 'active' : ''" to="/community">
                            <i class="fa-solid fa-bezier-curve icon"></i>
                            <span class="text nav-text">TrendsShift community</span>
                        </router-link>
                    </li>
                    <li class="nav-link">
                        <router-link :class="this.$route.name == 'Ranks' ? 'active' : ''" to="/ranks">
                            <i class="fa-solid fa-ranking-star icon"></i>
                            <span class="text nav-text">Ranks</span>
                        </router-link>
                    </li>
                    <li class="nav-link">
                        <router-link :class="this.$route.name == 'Brokers' ? 'active' : ''" to="/brokers">
                            <i class="fa-solid fa-handshake icon"></i>
                            <span class="text nav-text">Financial broker</span>
                        </router-link>
                    </li>
                    <li class="nav-link">
                        <router-link :class="this.$route.name == 'Calculator' ? 'active' : ''" to="/calculator">
                            <i class="fa-solid fa-calculator icon"></i>
                            <span class="text nav-text">Calculator</span>
                        </router-link>
                    </li> -->
                    <!-- <li class="nav-link">
                        <router-link :class="this.$route.name == 'Commissions' ? 'active' : ''" to="/commissions">
                            <i class='bx bx-wallet icon'></i>
                            <span class="text nav-text">Community comission</span>
                        </router-link>
                    </li> -->
                    <!-- <li class="nav-link">
                        <router-link :class="this.$route.name == 'Partners' ? 'active' : ''" to="/partners">
                            <i class="fa-solid fa-handshake icon"></i>
                            <span class="text nav-text">Partner pool</span>
                        </router-link>
                    </li> -->
                </ul>
            </div>

            <div class="bottom-content">
                <li class="">
                    <router-link to="">
                        <i class='fas fa-user icon'></i>
                        <span class="text nav-text">{{ user.first_name }}</span>
                    </router-link>
                </li>
                <li class="logoutBtn pointer round" @click="logout()">
                    <i class='fas fa-right-from-bracket icon'></i>
                    <span class="text nav-text">{{ $t('Logout')}}</span>
                </li>
            </div>
        </div>
    </nav>
  <div v-if="$store.state.loader==1 || $store.state.pageLoader==1" class="pf ov bgw" style="z-index: 9999999;">
    <div class="cc">
      <div class="loader">$</div>
    </div>
  </div>
  <div v-if="$store.state.formLoader==1" class="pf ov bgb40" style="z-index: 9999999;">
    <div class="cc">
      <div class="loader">$</div>
    </div>
  </div>
</template>
<script>
  export default {
    name: "WebLayout",
    data(){
      return{
        user:{},
        isMobile: /iPhone|iPad|iPod|Android/i.test(navigator.userAgent),
        at:null,
      }
    },
    created(){
      if(this.$cookies.isKey("_TrendLang")){
        if(this.$cookies.get("_TrendLang")=='en'){this.$i18n.locale='ar';}
        else{this.$i18n.locale='en';}
        this.changeLang()
      }
      if(this.$cookies.isKey("_TrendsU") && this.$cookies.isKey("_TrendsAT") ){this.user=this.$cookies.get("_TrendsU");this.at=this.$cookies.get("_TrendsAT")}
      else{this.$router.push({ name: 'SignIn'});}
    },
    methods:{
      toggleSidebar() {
        $('#sidebar').toggleClass('active')
        $('.mainDiv').toggleClass('sideBarActive')
      },
        logout(){
          this.$cookies.remove("_TrendsU")
          this.$cookies.remove("_TrendsAT")
          this.$router.push({ name: 'SignIn'});
        },
        closeSideBar(){
          console.log(214)
          $('#sidebar').removeClass('active')
          $('.mainDiv').removeClass('sideBarActive')
        },
      changeLang(){
        if(this.$i18n.locale=="en"){
          $('html').attr('lang', 'ar');  
          this.$i18n.locale='ar';
          $('body').addClass('rtl');
          const expirationTime =  10000 * 60 * 60;
          this.$cookies.set("_TrendLang", 'ar', expirationTime);
        }
        else{
          $('html').attr('lang', 'en');  
          this.$i18n.locale='en';
          $('body').removeClass('rtl');
          const expirationTime =  10000 * 60 * 60;
          this.$cookies.set("_TrendLang", 'en', expirationTime);
        }
      },
    },
    
  }
  
</script>
<style scoped>
.mainDiv {
  padding: 0 0px 0 0px;
  transition: all 0.3s ease;
}

body:not(.rtl) .mainDiv.sideBarActive{
  padding: 0 00px 0 270px;
}

body.rtl .mainDiv.sideBarActive{
  padding: 0 270px 0 00px;
}

@media all and (max-width:1250px) {
  body:not(.rtl) .mainDiv {
    padding: 0 0px 0 0px !important;
  }

  body.rtl .mainDiv {
    padding: 0 0px 0 0px !important;
  }

}
.dashboardBg {
  background-position: top left;
  background-repeat: repeat;
  background-size:75px 75px;
  background-attachment: fixed;
  min-height: 100vh;
}
body:not(.rtl) .sidebar {
    position: fixed;
    top: 0;
    left: -270px;
    height: 100%;
    width: 270px;
    padding: 10px;
    background: #2B2B2B;
    transition: all 0.3s ease;
    z-index: 2000;
}
body.rtl .sidebar {
    position: fixed;
    top: 0;
    right: -270px;
    height: 100%;
    width: 270px;
    padding: 10px;
    background: #2B2B2B;
    transition: all 0.3s ease;
    z-index: 2000;
}

body:not(.rtl) .sidebar.active{
left:0px
}
body.rtl .sidebar.active {
    right:0px;
}
.sidebar li {
    height: 50px;
    list-style: none;
    display: flex;
    align-items: center;
    margin-top: 10px;
}

.sidebar header .image,
.sidebar .icon {
    min-width: 60px;
    border-radius: 6px;
}

.sidebar .icon {
    min-width: 60px;
    border-radius: 6px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
}

.sidebar .text,
.sidebar .icon {
    color: #FFF;
    transition: all 0.3s ease;
}

.sidebar .text {
    font-size: 17px;
    font-weight: 500;
    white-space: nowrap;
    opacity: 1;
    margin-top: 3px;
}

.sidebar.xclose .text {
    opacity: 0;
}

.sidebar header {
    position: relative;
}

.sidebar header .image-text {
    display: flex;
    align-items: center;
}

.sidebar header .logo-text {
    display: flex;
    flex-direction: column;
}

header .image-text .name {
    margin-top: 2px;
    font-size: 18px;
    font-weight: 600;
}

header .image-text .profession {
    font-size: 16px;
    margin-top: -2px;
    display: block;
}

.sidebar header .image {
    display: flex;
    align-items: center;
    justify-content: center;
}


.sidebar .menu {
    margin-top: 40px;
}

.sidebar li a {
    list-style: none;
    height: 100%;
    background-color: transparent;
    display: flex;
    align-items: center;
    height: 100%;
    width: 100%;
    border-radius: 6px;
    text-decoration: none;
    transition: all 0.3s ease;
}

.sidebar li a:hover,.logoutBtn:hover {
    background-color: #EC6D2025;
}

.sidebar li a.active {
    background-color: #EC6D2050;
}

.sidebar .menu-bar {
    height: calc(100% - 55px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow-y: scroll;
}

.menu-bar::-webkit-scrollbar {
    display: none;
}

.bottom-content {
    border-top: 1px solid #ffffff30;
}

</style>